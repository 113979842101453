//@ts-expect-error
import { SearchValueType, SearchCriterion } from "hestia";
import { RegistrationStatusVM } from ".";

export default class RegistrationSearchCriteriaPM {
  any?: string;
  distributor?: string;
  rep?: string;
  customer?: string;
  catalogPart?: string;
  registrationNumber?: string;
  expirationFrom?: Date;
  expirationTo?: Date;
  status?: string;
  registrationStatuses?: number[];
  country?: string;
  projectName?: string;
  distributorInternalReference?: string;

  constructor(searchCriteria: any, countryList: any[], regStatuses: RegistrationStatusVM[]) {
    Object.assign(this, searchCriteria);

    if (this.status) {
      this.registrationStatuses = [];
      regStatuses.forEach(st => {
        // case insensitive compare (if user enters `active` - we should still match `Active`)
        // also duplicate regStatusNames intentionally  translated to all matching regStatusesId
        // so search for `Under review` should return result for (SUBMITTED_SR, SUBMITTED_CR, ...) all `Under Review`
        // statuses.
        if (st.registrationStatusName.toLowerCase() === this.status?.toLowerCase()) {
          this.registrationStatuses?.push(st.registrationStatusId);
        }
      });
    }

    // update the country name to countryCode
    if (this.country) {
      this.country = countryList.find(a => a.name === this.country).code;
    }
  }

  /** Gets the list of searchOptions specifically for RegistrationSearchCriteria */
  static getRegistrationSearchOptions(): SearchCriterion[] {
    const criteria = [
      new SearchCriterion("distributor"),
      new SearchCriterion("rep"),
      new SearchCriterion("customer"),
      new SearchCriterion("catalog-part"),
      new SearchCriterion("registration-number"),
      new SearchCriterion("expiration-from", SearchValueType.Date),
      new SearchCriterion("expiration-to", SearchValueType.Date),
      new SearchCriterion("status"),
      new SearchCriterion("project-name"),
      new SearchCriterion("country"),
      new SearchCriterion("distributor-internal-reference"),
    ] as SearchCriterion[];

    // sort searchCriteria alphabetically
    return criteria.sort((a, b) => (a.key > b.key ? 1 : a.key < b.key ? -1 : 0));
  }

  /** Adds countrySearch criterion with pre-populated values into search criteria. */
  static addCountrySearchCriterion(searchCriteria: SearchCriterion[], countryList: any[]) {
    const index = searchCriteria.findIndex(a => a.key === "country" && a.searchValueType === SearchValueType.String);

    if (~index) {
      const criterion = new SearchCriterion(
        "country",
        SearchValueType.Predefined,
        countryList.map(a => a.name)
      );
      // replace the existing `country` searchCriterion, that was as placeholder with a `country` searchCriterion
      // that has predefined values. Need to have a placeHolder `country` searchCriterion, in case user searches
      // by country from navigation bar and routed with `country` searchCriterion to searchResult
      searchCriteria.splice(index, 1, criterion);
    }
  }

  /** Adds statusSearch criterion with pre-populated values into search criteria. */
  static addRegStatusSearchCriterion(searchCriteria: SearchCriterion[], regStatuses: RegistrationStatusVM[]) {
    const index = searchCriteria.findIndex(x => x.key === "status" && x.searchValueType === SearchValueType.String);

    if (~index) {
      // get regStatuses with no duplicates (e.g. `Under review`)
      const statusesName = [...new Set(regStatuses.map(x => x.registrationStatusName))];
      const criterion = new SearchCriterion("status", SearchValueType.Predefined, statusesName);

      // replacing existing searchCriterion for `status`  with criterion with predefined values.
      // similar to addCountrySearchCriterion().
      searchCriteria.splice(index, 1, criterion);
    }
  }
}