
import { defineComponent } from "vue";
import { GridColumnProps } from "@progress/kendo-vue-grid";
// @ts-expect-error: import from hestia
import { readOnlyGrid, renderCellWithRoute } from "hestia";
import { RegistrationService } from "@/services";
import { routeNames } from "@/router";
import { ExcelExportOptions } from "@progress/kendo-vue-excel-export";

export default defineComponent({
  name: "RecentRegistrationsWidget",
  components: {
    readOnlyGrid,
  },
  inject: ["userAuth", "api"],
  emits: ["showDraftsDialog"],
  data() {
    return {
      isLoading: false,
      gridItems: [] as any[],
      gridColumns: [
        {
          field: "registrationNumber",
          title: "Registration",
          filter: "text",
          cell: renderCellWithRoute(this.getRegistrationLink, "_blank"),
        },
        { field: "distributorName", title: "Distributor", filter: "text" },
        { field: "customerName", title: "Customer", filter: "text" },
        { field: "catalogPart", title: "Catalog Part", filter: "text" },
        { field: "designValue", title: "Design Value ($)", filterable: "numeric", width: "125px", format: "{0:##,#}" },
        { field: "registrationStatusName", title: "Status", filter: "text" },
        { field: "expirationDate", title: "Expiration Date", filter: "date", format: "{0:d-MMM-y}" },
        { field: "requesterDisplayName", title: "Requested By", filter: "text" },
        { field: "assignedToDisplayName", title: "Assigned To", filter: "text", hidden: !this.userAuth.isAllegro },
      ] as GridColumnProps[],
      registrationService: new RegistrationService(this.api),
      routeNames,
      excelColumns: [
          { field: "registrationNumber", title: "Registration Number" },
          { field: "distributorName", title: "Distributor Name" },
          { field: "customerName", title: "Customer Name" },
          { field: "countryName", title: "Country" },
          { field: "catalogPart", title: "Catalog Part" },
          { field: "designValue", title: "Design Value" },
          { field: "registrationStatusName", title: "Registration Status Name",
          },
          {
            field: "expirationDate",
            title: "Expiration Date",
            format: "{0:d-MMM-y}",
          },
          { field: "requesterDisplayName", title: "Requested By" },
          { field: "distributorInternalReference", title: "Distributor Internal Reference" }
        ] as any[]
    };
  },
  mounted() {
    this.loadRegistrationList();
  },
  methods: {
    async loadRegistrationList() {
      this.isLoading = true;
      try {
        const registrationList = await this.registrationService.getRecentRegistrationList();
        this.gridItems = registrationList.recentRegistrations;
      } catch (error) {
        (this as any).$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    },
    getRegistrationLink(dataItem: any): string {
      return `/registrations/details/${dataItem.registrationNumber}`;
    }
  },
    computed: {
    excelExportSettings(): ExcelExportOptions {
      const columns = [...this.excelColumns];
      if (this.userAuth.isAllegro) {  
        columns.push({ field: "assignedToDisplayName", title: "Assigned To" });
      }

      return {
        data: this.gridItems,
        fileName: "export.xlsx",
        columns: columns
      
      } as ExcelExportOptions;
    },
  },
});
