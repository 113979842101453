import RegistrationDocumentVM from "./registration-document-vm";

export default class FilePM {
    fileName = "";
    referenceName = "";

    copyFromViewModel(document: RegistrationDocumentVM) {
        this.fileName = document.documentName || "";
        this.referenceName = document.referenceName || "";
        return this;
    }
}