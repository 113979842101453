import { Deserializable } from "@/interfaces";

export default class DebitAuthorizationVM implements Deserializable<DebitAuthorizationVM> {
  debitAuthorizationId = 0;
  debitAuthorizationNumber = "";
  quoteId: number | null = null;
  authorizedQuantity = 0;
  claimedQuantity = 0;
  debitAuthorizationStatus = "";
  distributorCost = 0;
  distributorResale = 0;
  expirationDate: Date | null = null ;

  deserialize(model: DebitAuthorizationVM): DebitAuthorizationVM {
   if(model) {
     Object.assign(this, model);
     if(model.expirationDate) this.expirationDate = new Date(model.expirationDate);
   }

   return this;
  }
}