
import { defineComponent } from "vue";
import { Tooltip } from "@progress/kendo-popups-vue-wrapper";
import { PreviousRegistration, PreviousRegistrationPM } from "@/models";
import PrevRegDialog from "./prev-reg-dialog.vue";

export default defineComponent({
  name: "inputPrevReg",
  components: {
    tooltip: Tooltip,
    prevRegDialog: PrevRegDialog,
  },
  emits: ["update"],
  props: {
    prevRegistrationCriteria: {
      type: PreviousRegistrationPM,
      required: true,
    },
    prevReg: {
      type: PreviousRegistration,
      default: null
    }, 
  },
  data() {
    return {
      tooltipContent: "<p style='padding-bottom: 0 !important'>To link previous registration, please select Distributor, Customer and Part first.</p>",
      showPrevDialog: false,
      previousRegistration: this.prevReg || null
    };
  },
  computed: {
    isPrevRegSelected(): boolean {
      return Boolean(this.previousRegistration && this.previousRegistration.registrationId);
    },
    isPrevRegistrationActive(): boolean {
      return Boolean(
        this.prevRegistrationCriteria.distributorCode && this.prevRegistrationCriteria.customerName && this.prevRegistrationCriteria.catalogPart
      );
    },
  },
  methods: {
    updatePrevReg(newVal: PreviousRegistration) {
      this.previousRegistration.registrationId = newVal?.registrationId;
      this.previousRegistration.registrationNumber = newVal?.registrationNumber;
      this.$emit("update", this.previousRegistration);
    },
  }
});
