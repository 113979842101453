
import { InlineEditable } from "@/interfaces";
import { defineComponent, PropType } from "vue";
export default defineComponent({
    props: {
        dataItem: Object as PropType<InlineEditable>
    },
    computed: {
        cancelButtonLabel(): string {
            return this.dataItem?.id ? "Cancel" : "Discard";
        }
    },
    methods: {
        editHandler() {
            this.$emit("edit", {dataItem:this.dataItem});
        },
        removeHandler() {
            this.$emit("remove", {dataItem:this.dataItem});
        },
        addUpdateHandler() {
            this.$emit("save", {dataItem:this.dataItem});
        },
        cancelDiscardHandler() {
            this.$emit("cancel", {dataItem:this.dataItem});
        }
    }
});
