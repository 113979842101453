import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "review-manager-container is-flex is-centered"
}
const _hoisted_2 = { class: "message is-info" }
const _hoisted_3 = { class: "message-body" }
const _hoisted_4 = { class: "level" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "is-size-6 has-text-danger mb-4" }
const _hoisted_8 = {
  key: 0,
  class: "is-size-6 has-text-danger mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_review_manager = _resolveComponent("review-manager")

  return (_ctx.userTaskReviewVM?.userTaskId)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("article", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode("div", _hoisted_4, [
              (_ctx.userTaskReviewVM)
                ? (_openBlock(), _createBlock("p", _hoisted_5, _toDisplayString(_ctx.userTaskReviewVM.shortName) + " is pending your review ", 1))
                : _createCommentVNode("", true),
              _createVNode(_component_review_manager, {
                key: _ctx.userTaskReviewVM?.userTaskId,
                outcomes: _ctx.userTaskReviewVM.reviewOutcomes,
                fullName: _ctx.userTaskReviewVM.fullName,
                outcomeIdPropName: 'userTaskOutcomeId',
                maxCommentsLength: 250,
                onReviewDone: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveReviewDecision($event)))
              }, {
                "dialog-activator": _withCtx(({ openDialog }) => [
                  _createVNode("div", null, [
                    _createVNode("button", {
                      type: "button",
                      onClick: openDialog
                    }, "Review", 8, ["onClick"])
                  ])
                ]),
                "dialog-subtitle": _withCtx(() => [
                  (_ctx.isRenewal)
                    ? (_openBlock(), _createBlock("div", _hoisted_6, [
                        _createVNode("div", _hoisted_7, _toDisplayString(`Renewal Request ${_ctx.registration.version + 1} of a maximum of ${_ctx.registration.maxRecommendedRenewalCount} recommended.`), 1),
                        (_ctx.doesRenewalExceedsMaxRecommendedCount)
                          ? (_openBlock(), _createBlock("div", _hoisted_8, " This request exceeds the recommended renewal requests. "))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["outcomes", "fullName"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}