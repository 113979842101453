
// @ts-expect-error
import { loading } from "aml";

export default {
    name: "LoadingWrapper",
    components: {
        loading
    },
    props: {
        isLoading: Boolean
    }
}
