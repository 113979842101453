import { DateTime } from "luxon";
  
export function formatDate(date: Date | undefined): string {
  return date ? DateTime.fromJSDate(new Date(date)).toFormat("d-MMM-y") : "";
}

export function formatNumber(amount: number | undefined, fractionDigits = 0): string {
  return (amount != null && amount != undefined) ? amount.toLocaleString(undefined, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }) : "";
}

const FormatHelpers = {
  formatDate,
  formatNumber
};
export default FormatHelpers;