import { Deserializable } from "@/interfaces";
import { RecentRegistrationVM } from ".";

export default class RecentRegistrationListVM implements Deserializable<RecentRegistrationListVM> {
  canCreateRegistration = false;
  recentRegistrations = [] as RecentRegistrationVM[];

  deserialize(model: RecentRegistrationListVM): RecentRegistrationListVM {
    if (model) {
      Object.assign(this, model);
      if (model.recentRegistrations) {
        this.recentRegistrations = model.recentRegistrations.map(a => new RecentRegistrationVM().deserialize(a));
      }
    }

    return this;
  }
}
