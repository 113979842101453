import ApiClient  from "@/apiClient";
import TypedApiClient  from "./typed-api-client";
import { RegistrationStatusVM } from "@/models";

const SERVICE_BASE_URL = "RegistrationStatuses";

export default class RegistrationStatusService extends TypedApiClient {
  constructor(apiClient: ApiClient) {
    super(apiClient);
}
  async getStatuses(): Promise<RegistrationStatusVM[]> {
    return await this.getCache(`${SERVICE_BASE_URL}`, RegistrationStatusVM) as RegistrationStatusVM[]; 
  }
}