//@ts-expect-error
import { utils } from "hestia";
import { Deserializable } from "@/interfaces";

export default class RegistrationHistoryRecordVM implements Deserializable<RegistrationHistoryRecordVM> {
  what = "";
  whom = "";
  when = new Date();
  whenForSearch = new Date();
  completionComment?: string;

  deserialize(model: RegistrationHistoryRecordVM): RegistrationHistoryRecordVM {
    if (model) {
      Object.assign(this, model);
      if (this.when) {
        this.when = new Date(this.when);
        // Date without time is used for Kendo Grid search - no support for time filter
        this.whenForSearch = utils.stripTimeFromDate(this.when) || this.when;
      }
    }
    return this;
  }
}
