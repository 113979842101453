import ApiClient  from "@/apiClient";
import RegistrationStatusPostModel from "@/models/submit-renewal-pm";
import TypedApiClient  from "./typed-api-client";
import { 
  DraftRegistrationVM, RegistrationEditVM, RegistrationDetailVM, 
  RegistrationDocumentVM, RegistrationRowVersionPM, RegistrationSearchCriteriaPM, 
  RegistrationSearchItem, SubmitRenewalPM, RegistrationEditPM, PreviousRegistrationPM,
  PreviousRegistrationVM, RegistrationHistoryRecordVM, DebitAuthorizationVM, QuoteVM, ExpireNowPM, RecentRegistrationListVM, IncentiveProgramEditPM } 
from "@/models";

const REGISTRATION_API_URL = "Registrations";
export default class RegistrationService extends TypedApiClient {
  constructor(apiClient: ApiClient) {
    super(apiClient);
}
  async getRegistrationDetails(registrationNumber: string): Promise<RegistrationDetailVM> {
    return await this.apiClient.get(`${REGISTRATION_API_URL}/${registrationNumber}/details`, undefined, undefined);
  }

  async getRegistrationDocuments(registrationId: number): Promise<RegistrationDocumentVM[]> {
    return await this.get(`${REGISTRATION_API_URL}/${registrationId}/documents`, RegistrationDocumentVM) as RegistrationDocumentVM[];
  }

  async changeStatus(registrationStatusPostModel: RegistrationStatusPostModel): Promise<void> {
    return await this.apiClient.post(registrationStatusPostModel, REGISTRATION_API_URL + "/Status");
  }

  async cancelSubmission(registrationId: number, rowVersion: string): Promise<void> {
    return await this.apiClient.post(new RegistrationRowVersionPM(rowVersion), `${REGISTRATION_API_URL}/${registrationId}/CancelSubmission`);
  }
  
  async expireRegistration(registrationId: number, comments: string, rowVersion: string): Promise<void> {
    return await this.apiClient.post(new ExpireNowPM(registrationId, comments, rowVersion), `${REGISTRATION_API_URL}/Expire`);
  }
  
  async cancelRenewal(registrationId: number, rowVersion: string): Promise<void> {
    return await this.apiClient.post(new RegistrationRowVersionPM(rowVersion), `${REGISTRATION_API_URL}/${registrationId}/CancelRenewal`);
  }

  async returnToDraftAfterReject(registrationId: number, rowVersion: string): Promise<void> {
    return await this.apiClient.post(new RegistrationRowVersionPM(rowVersion), `${REGISTRATION_API_URL}/${registrationId}/ReturnToDraftAfterReject`);
  }

  async submitRenewal(postModel: SubmitRenewalPM): Promise<void> {
    return await this.apiClient.post(postModel, `${REGISTRATION_API_URL}/SubmitRenewal`);
  }

  async getDrafts(): Promise<DraftRegistrationVM[]> {
    return await this.get<DraftRegistrationVM>(`${REGISTRATION_API_URL}/Drafts`, DraftRegistrationVM) as DraftRegistrationVM[];
  }

  async searchRegistration(criteria: RegistrationSearchCriteriaPM, shouldLoadLatestQuotesAndDebitAuths = false): Promise<RegistrationSearchItem[]> {
    const query = `?shouldLoadLatestQuotesAndDebitAuths=${shouldLoadLatestQuotesAndDebitAuths}`;
    return await this.post(criteria, RegistrationSearchItem, `${REGISTRATION_API_URL}/search${query}`) as RegistrationSearchItem[];
  }

  async getEditDetails(registrationNumber: string): Promise<RegistrationEditVM> {
    return await this.get(`${REGISTRATION_API_URL}/${registrationNumber}`, RegistrationEditVM) as RegistrationEditVM;
  }

  async editRegistration(id: number, submit: boolean, model: RegistrationEditPM): Promise<number> {
    const query = `${submit ? '?submit=true' : ''}`;
    return await this.apiClient.put(model, `${REGISTRATION_API_URL}/${id}${query}`);
  }

  async getPreviousRegistrations(previousRegistration: PreviousRegistrationPM): Promise<PreviousRegistrationVM[]> {
    return await this.post(previousRegistration, PreviousRegistrationVM, `${REGISTRATION_API_URL}/previousRegistrations`) as PreviousRegistrationVM[];
  }

  async getRegistrationHistoryRecords(registrationId: number): Promise<RegistrationHistoryRecordVM[]> {
    return await this.get<RegistrationHistoryRecordVM>(`${REGISTRATION_API_URL}/${registrationId}/HistoryRecords`, RegistrationHistoryRecordVM) as RegistrationHistoryRecordVM[];
  }

  async getDebitAuthorizations(registrationNumber: string): Promise<DebitAuthorizationVM[]> {
    return await this.get<DebitAuthorizationVM>(`${REGISTRATION_API_URL}/${registrationNumber}/debitAuthorizations`, DebitAuthorizationVM) as DebitAuthorizationVM[];
  }

  async getQuotes(registrationNumber: string): Promise<QuoteVM[]> {
    return await this.get<QuoteVM>(`${REGISTRATION_API_URL}/${registrationNumber}/quotes`, QuoteVM) as QuoteVM[];
  }

  async expireNow(postModel: ExpireNowPM): Promise<void> {
    return await this.apiClient.post(postModel, `${REGISTRATION_API_URL}/Expire`);
  }

  async getRecentRegistrationList(): Promise<RecentRegistrationListVM> {
    return await this.get<RecentRegistrationListVM>("Registrations/recent", RecentRegistrationListVM) as RecentRegistrationListVM;
  }

  async editIncentiveProgram(incentiveProgramEditModel: IncentiveProgramEditPM): Promise<void> {
    return await this.apiClient.put(incentiveProgramEditModel, `${REGISTRATION_API_URL}/updateIncentiveProgram`);
  }
}