export default class PreviousRegistrationPM {
  distributorCode?: string;
  customerName?: string;
  catalogPart?: string;

  constructor(distributorCode: string, customerName: string, catalogPart: string) {
    this.distributorCode = distributorCode;
    this.customerName = customerName;
    this.catalogPart = catalogPart;
  }
}
