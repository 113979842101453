
import { computed, defineComponent, inject, PropType } from "vue";
import {
  Grid,
  GridColumnProps,
  GridItemChangeEvent,
  GridToolbar,
} from "@progress/kendo-vue-grid";
import { useGridPaging, useGridFiltering, useGridSorting } from "@/composables";
import EditItemCell from "./edit-item-cell.vue";
import InlineEditable from "@/interfaces/inline-editable";
import Message from "@/interfaces/message";

const ITEM_IN_EDIT_ERROR = "An item is already in Edit.";

export default defineComponent({
  name: "EditableGrid",
  components: {
    Grid,
    GridToolbar,
    EditItemCell,
  },
  emits: {
    save: Object,
  },
  props: {
    items: {
      type: Array as PropType<InlineEditable[]>,
      required: true,
    },
    columns: {
      type: Array as PropType<GridColumnProps[]>,
      required: true,
    },
    addNew: {
      type: Function as PropType<() => InlineEditable>,
    },
    /** Validator function must return false if valid or error message if not valid */
    validator: {
      type: Function as PropType<(item: any, originalItem: any) => boolean | string>,
    },
    isToolBarRequired: {
      type: Boolean,
      default: true,
    },
    isReadOnly: Boolean
  },
  setup(props, { emit }) {
    const $error = inject("$error") as (msg: Message) => void;
    const $warn = inject("$warning") as (msg: Message) => void;

    const gridColumns = props.columns as GridColumnProps[];

    // Sorting, filtering, paging
    const gridItems = computed(() => props.items);
    const { sort, sortChange, sortedItems } = useGridSorting(gridItems);
    const { filterChange, filteredItems, filter } = useGridFiltering(
      sortedItems
    );
    const {
      pageChangeHandler,
      pageItems,
      pageSize,
      pageable,
      skip,
      take,
      total,
    } = useGridPaging(filteredItems, [50, 100, 200]);
    
    // Update changed properties of cloned item
    const onItemChange = (event: GridItemChangeEvent) => {
      if (!gridItems?.value) return;
      
      if (!event.dataItem || !event?.field) return;
      event.dataItem[event.field] = event.value as InlineEditable;
    };

    
    let originalItem: undefined | InlineEditable = undefined;
    const itemInEditMode = computed(() =>
      gridItems.value?.find((item) => item.inEdit)
    );
    const isGridInEditMode = computed(() => !!itemInEditMode.value);
    
    const onEditStart = (event: GridItemChangeEvent) => {
      if (isGridInEditMode.value) {
        $warn({ message: ITEM_IN_EDIT_ERROR });
        return;
      }
      // TODO: be careful with getters properties in column property, since
      // after Object.assign() they are not accessible anymore.
      originalItem = Object.assign({}, event.dataItem);
      event.dataItem.inEdit = true;
    };

    const onAddNew = () => {
      if (!props.addNew) return;
      if (isGridInEditMode.value) {
        $warn({ message: ITEM_IN_EDIT_ERROR });
        return;
      }
      // keep original state and add new item to list
      const newItem = props.addNew();
      originalItem = Object.assign({}, newItem);
      newItem.inEdit = true;
      gridItems.value.unshift(newItem);
    };

    const showValidationError = (validationMessage: string) => {
      $error({ message: validationMessage });
    };

    /** Validate before save if validator provided */
    const onSave = (event: GridItemChangeEvent) => {
      const validationMessage =
        props.validator && props.validator(event.dataItem, originalItem);
      if (!props.validator || !validationMessage) {
        emit("save", event.dataItem);
      } else {
        showValidationError(validationMessage as string);
      }
    };

    /** Discard new item or restore original item on cancel */
    const onCancel = () => {
      if (!itemInEditMode.value) return;
      const index = gridItems.value.findIndex(
        (a) => itemInEditMode.value?.id === a.id
      );
      if (itemInEditMode.value.id === 0) {
        gridItems.value.splice(index, 1);
      } else {
        if (!originalItem) return;
        gridItems.value.splice(index, 1, originalItem);
      }
    };

    return {
      gridItems,
      pageItems,
      gridColumns,
      onItemChange,
      onCancel,
      onEditStart,
      onSave,
      onAddNew,
      isGridInEditMode,
      pageSize,
      skip,
      take,
      total,
      pageable,
      pageChangeHandler,
      filter,
      filterChange,
      sortChange,
      sort,
    };
  },
});
