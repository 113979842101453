import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar_search_box = _resolveComponent("navbar-search-box")

  return (!_ctx.isOnSearchPage)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_navbar_search_box, {
          searchCriteria: _ctx.searchKeys,
          onSearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSearch($event)))
        }, null, 8, ["searchCriteria"])
      ]))
    : _createCommentVNode("", true)
}