import { RegistrationStatus } from "@/enums";
import { Deserializable } from "@/interfaces";
//@ts-expect-error
import { utils } from "hestia";

export default class PreviousRegistrationVM implements Deserializable<PreviousRegistrationVM> {
  registrationId = 0;
  registrationNumber?: string;
  distributorName?: string;
  customerName?: string;
  catalogPart?: string;
  status?: RegistrationStatus;
  expirationDate?: Date;
  requesterDisplayName?: string;

  // Only client
  expiryDate = new Date();

  deserialize(model: PreviousRegistrationVM): PreviousRegistrationVM {
      if(model) {
          Object.assign(this, model);
          if(this.expirationDate) {
              this.expirationDate = new Date(this.expirationDate);
              // Date without time is used for Kendo Grid search - no support for time filter
              this.expiryDate = utils.stripTimeFromDate(this.expirationDate) || this.expirationDate;
          }
      }
      return this;
  }
}
