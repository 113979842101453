export default class ReviewDecisionPM {
    registrationId: number;
    registrationRowVersion: string;
    userTaskId: number;
    userTaskRowVersion: string;
    userTaskOutcomeId: number;
    comments?: string;

    constructor(userTaskOutcomeId: number, registrationId: number, registrationRowVersion: string, userTaskId: number, userTaskRowVersion: string, comments?: string) {
        this.userTaskOutcomeId = userTaskOutcomeId;
        this.comments = comments;
        this.registrationId = registrationId;
        this.registrationRowVersion = registrationRowVersion;
        this.userTaskId = userTaskId;
        this.userTaskRowVersion = userTaskRowVersion;
    }
}