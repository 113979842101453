export enum UserRole {
  IsDistributor = "isDistributor",
  IsRep = "isRep",
  IsGlobalReviewer = "isGlobalReviewer",
  CanCreateRegistrations = "canCreateRegistrations",
  CanViewRegistrationHistory = "canViewRegistrationHistory",
  CanViewAnyRegistration = "canViewAnyRegistration",
  CanExpireNow = "canExpireNow",
  CanViewTerritoryRegistrations = "canViewTerritoryRegistrations",
  CanViewDistributorSettings = "canViewDistributorSettings",
  CanManageDistributorSettings = "canManageDistributorSettings",
  CanViewIncentivePrograms = "canViewIncentivePrograms",
  CanManageIncentivePrograms = "canManageIncentivePrograms",
  IsAllegro = "isAllegro"
}