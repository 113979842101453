import ApiClient  from "@/apiClient";
import { DistributorSettingPM, DistributorSettingVM } from "@/models";
import TypedApiClient  from "./typed-api-client";

const DISTRIBUTOR_SETTINGS_API = 'distributorSettings';

export default class DistributorSettingsService extends TypedApiClient {
    constructor(apiClient: ApiClient) {
        super(apiClient);
    }

    async getDistributorSettings(): Promise<DistributorSettingVM[]> {
        return await this.get<DistributorSettingVM>(DISTRIBUTOR_SETTINGS_API, DistributorSettingVM) as DistributorSettingVM[];
    }

    async createDistributorSetting(setting: DistributorSettingPM): Promise<DistributorSettingPM> {
        return await this.post<DistributorSettingPM>(setting, DistributorSettingPM, DISTRIBUTOR_SETTINGS_API) as DistributorSettingPM;
    }

    async updateDistributorSetting(setting: DistributorSettingPM): Promise<DistributorSettingPM> {
        return await this.put<DistributorSettingPM>(setting, DistributorSettingPM, `${DISTRIBUTOR_SETTINGS_API}/${setting.id}`) as DistributorSettingPM;
    }

    async getDistributorRevenueMinimum(distributorCode: string): Promise<number> {
        return await this.apiClient.getCache(`${DISTRIBUTOR_SETTINGS_API}/${distributorCode}/revenueMinimum`, undefined) as number;
    }
}