
import { defineComponent } from "vue";
// @ts-expect-error: referencing hestia alias
import { ebizApps, ebizAppsItem } from "hestia";

export default defineComponent({
   name: 'MyApps',
   components: {
     ebizAppsItem
   },
   data() {
    return {
      apps: []
    }
  },
   methods: {
    ...ebizApps
  },
  mounted() {
    this.apps = this.getUserApps();
  }
});
