
import { PropType, defineComponent } from "vue";
// @ts-expect-error
import { modalDialog } from "aml";
// @ts-expect-error
import {  selectOption } from "hestia";
import { IncentiveProgramEditPM } from "@/models";
import { OptionVM } from "@/interfaces";

export default defineComponent({
  name: "RegistrationEditIncentiveProgramDialog",
  props: {
    registrationNumber: {
      type: String,
      required: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    incentivePrograms: {
      type: Object as PropType<OptionVM[]>,
      required: true,
    },
    currentProgram: {
      type: String,
    },
    modelValue: {
      type: Object as PropType<IncentiveProgramEditPM>,
      required: true,
      default: () => new IncentiveProgramEditPM(),
    },
  },
  components: {
    modalDialog,
    selectOption
  },
  beforeMount() {
    this.incentiveProgramEditModel.incentiveProgramId = this.currentProgramId;
  },
  emits: ["resolveDialogAction", "update:modelValue"],
  data() {
    return {
      incentiveProgramEditModel: new IncentiveProgramEditPM(),
      formId: "editIncentiveProgramCommentsForm"
    };
  },
  computed: {
    currentProgramId(): number | null {
      const incentiveProgramMatch = this.incentivePrograms?.find(a => a.name === this.currentProgram);
      return this.currentProgram ? (incentiveProgramMatch?.id || -2) : -1;
    },
    isIncentiveChanged(): boolean {
      return this.incentiveProgramEditModel.incentiveProgramId !== this.currentProgramId;
    },
    isCurrentProgramNotAvailable(): boolean {
      return this.currentProgramId === -2;
    }
  },
  methods: {
    resolveDialogAction(proceed: boolean) {
      if (proceed) {
        if (!this.isFormValid()) return;
        if (!this.isIncentiveChanged) {
          this.$error({ message: "Incentive program is not changed" });
          return;
        }
        // incentive program id -1 is used for None in dropdown, but we need null for the api
        if (this.incentiveProgramEditModel.incentiveProgramId === -1) {
          this.incentiveProgramEditModel.incentiveProgramId = null;
        }
        this.$emit("update:modelValue", this.incentiveProgramEditModel);
        this.$emit("resolveDialogAction", true);
      } else {
        this.$emit("resolveDialogAction", false);
      }
    },
    isFormValid() {
      const fieldErrors = this.$validate(this.formId);
      return !fieldErrors.length;
    },
    getIncentiveProgramsPromise() {
      return new Promise<OptionVM[]>((resolve) => {
        const options: OptionVM[] = this.incentivePrograms.slice();
        options.unshift({ id: -1, name: "None" });
        if (this.isCurrentProgramNotAvailable) {
          // incentive program id -2 is used for program that is not available anymore
          options.unshift({ id: -2, name: this.currentProgram });
        }
        resolve(options)});
    }
  },
});
