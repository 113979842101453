<template>
  <div :id="routeId">
    <navbar v-if="!viewOnly" :menuItems="menuItems">
      <template #searchBox>
        <navigation-search-box></navigation-search-box>
      </template>
    </navbar>

    <div class="container">
      <router-view v-slot="{ Component }">
        <transition name="fade-in-down" appear mode="out-in">
          <!-- View has to have only one root element so transition can be applied -->
          <div>
            <component :is="Component" />
          </div>
        </transition>
      </router-view>
      <loading-manager />
      <modal-error :isAllegroUser="userAuth.isAllegro" :supportEmail="supportEmail"></modal-error>
    </div>
  </div>
  <env-identifier 
    v-if="!isProd" 
    v-once 
    :environmentName="environmentName" 
    :buildNumber="buildNumber" 
    :branchName="branchName" />
</template>

<script>
import ApiClient from "./apiClient";
import { mapGetters } from "vuex";
import { navbar } from "hestia";
import { NavigationSearchBox } from "@/components";
import { GetNavbarMenuItems } from "@/models";
import { UserAuth } from "@/helpers";
import { envIdentifier } from "hestia";

const PRODUCTION = "production";

export default {
  components: {
    navbar,
    NavigationSearchBox,
    envIdentifier
  },
  data() {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
      environmentName: process.env.VUE_APP_ENVIRONMENT_NAME,
      buildNumber: process.env.VUE_APP_BUILD_BUILDNUMBER,
      branchName: process.env.VUE_APP_BUILD_SOURCEBRANCH,
      userAuth: UserAuth,
      menuItems: GetNavbarMenuItems(),
    };
  },
  provide() {
    return {
      api: new ApiClient(this),
      userAuth: UserAuth
    };
  },
  computed: {
    routeId() {
      const pageName = this.$route.name;
      if (!pageName) return;
      return `${pageName.toLowerCase()}-page`;
    },
    viewOnly() {
      const routeMeta = this.$route.meta;
      return (!this.isAuthenticated && !routeMeta.allowAnonymous) || routeMeta.viewOnly;
    },
    isProd() {
      return this.environmentName === PRODUCTION;
    },
    ...mapGetters(["isAuthenticated"])
  },
  watch: {
    isAuthenticated() {
      this.menuItems = GetNavbarMenuItems();
    }
  }
};
</script>
