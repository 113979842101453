// @ts-nocheck
import 'regenerator-runtime';
import 'animate.css'
import '@progress/kendo-theme-default/dist/all.css'
import './site.css'
import { createApp } from 'vue'
import app from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { auth, authConfig } from './configs/auth'
import { modal, loadingManager } from 'aml'
import { errorHandling, notifications, formValidation } from 'hestia'

export default
    createApp(app)
        .use(store)
        .use(router)
        .use(auth.plugin, authConfig)
        .use(errorHandling.plugin, { store, router })
        .use(formValidation.plugin, { store })
        .use(loadingManager.plugin, { store })
        .use(modal.plugin, { store })
        .use(notifications.plugin, { store })
        .mount('#app')