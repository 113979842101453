import { InlineEditable, Deserializable } from "@/interfaces";

export default class DistributorSettingPM implements InlineEditable, Deserializable<DistributorSettingPM> {
    id = 0;
    code?: string;
    registrationRevenueMinimum = 0;
    rowVersion?: string;

    // UI property for Kendo Grid edit feature
    inEdit = false;

    // UI property 
    name = "";

    deserialize(model: DistributorSettingPM): DistributorSettingPM {
        if (model) {
            Object.assign(this, model);
        }

        return this;
    }
}