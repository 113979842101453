
import { defineComponent } from "vue";
// @ts-expect-error
import { modalCard } from "aml";
import { DraftRegistrationService } from "@/services";
// @ts-expect-error 
import { readOnlyGrid, renderFilterableCellWithDatetime, renderIconCell } from "hestia";
import { DraftRegistrationVM } from "@/models";
import { GridColumnProps, GridRowClickEvent } from "@progress/kendo-vue-grid";
import { routeNames } from '@/router';

export default defineComponent({
  name: "DraftsDialog",
  components: {
    modalCard,
    readOnlyGrid,
  },
  inject: ["api"],
  emits: ["done"],
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drafts: [] as DraftRegistrationVM[],
      apiClient: new DraftRegistrationService(this.api),
      isLoading: false,
      columns: [
        {
            filterable: false,
            sortable: false,
            resizable: false,
            cell: renderIconCell(() => "fas fa-edit fa-fw"),
            className: "has-text-info is-size-6 is-centered",
            width: 45
        },
        {
          field: "description",
          title: "Description",
          filterable: true,
          filter: "text",
          className: "is-size-6",
        },
        {
          field: "lastEditDateForSearch",
          title: "Last Edit",
          filterable: true,
          filter: "date",
          width: 200,
          className: "is-size-6",
          cell: renderFilterableCellWithDatetime("lastEditDate", "HH:mm:ss | d-MMM-y")
        },
      ] as GridColumnProps[],
    };
  },
  methods: {
    async loadDraftItems() {
      try {
        this.isLoading = true;
        const list = await this.apiClient.getDrafts();
        this.drafts = list;
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.$emit("done");
    },
    rowClicked(event: GridRowClickEvent) {
      this.$emit("done");
      this.$closeModal(() => {
        const item = event.dataItem as DraftRegistrationVM;
        const name = item.isDraftRegistration ? routeNames.registrationCreate : routeNames.registrationEdit;
        const routeConfig = item.isDraftRegistration ? { query: { draftId: item.id }} : { 
          params: { registrationNumber: item.registrationNumber } 
        };
        this.$router.push({ name , ...routeConfig });
      });
    }
  },
  watch: {
    isVisible(newVal) {
      if(newVal) this.loadDraftItems();
    }
  }
});
