
import { defineComponent } from "vue";
import '@progress/kendo-ui/js/kendo.tooltip';
//@ts-expect-error
import { navbarSearchBox, SearchCriterion } from "hestia";
import { RegistrationSearchCriteriaPM } from "@/models";
import { RegistrationStatusService } from "@/services";
export default defineComponent({
  name: "NavigationSearchBox",
  components: {
    navbarSearchBox,
  },
  inject: ["api"],
  data() {
    return {
      searchKeys: RegistrationSearchCriteriaPM.getRegistrationSearchOptions(),
      registrationStatusService: new RegistrationStatusService(this.api)
    };
  },
  computed: {
    isOnSearchPage(): boolean {
      return this.$route.name === "search";
    },
  },
  methods: {
    onSearch(criteria: SearchCriterion) {
      this.$router.push({ name: "search", query: { ...criteria } });
    },
  },
  async beforeMount() {
    // load static values
    const [countryList, statuses] = await Promise.all([
      this.api.getCountries(),
      this.registrationStatusService.getStatuses()
    ]); 

    RegistrationSearchCriteriaPM.addCountrySearchCriterion(this.searchKeys, countryList);
    RegistrationSearchCriteriaPM.addRegStatusSearchCriterion(this.searchKeys, statuses);
  },
});
