import { CompositeFilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { GridFilterChangeEvent } from "@progress/kendo-vue-grid";
import { computed, ref, Ref } from "vue";

export default function useGridFiltering(gridItems: Ref<any[]>) {
    const filter = ref({
        logic: "and",
        filters: [],
      } as CompositeFilterDescriptor);
  
      const filterChange = (event: GridFilterChangeEvent) => {
        filter.value = event.filter;
      };
      const filteredItems = computed(() => filterBy(gridItems.value, filter.value));

      return { filterChange, filteredItems, filter };
}