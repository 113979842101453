import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "loading-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    ($props.isLoading)
      ? (_openBlock(), _createBlock(_component_loading, {
          key: 0,
          class: "loading-bar"
        }))
      : _createCommentVNode("", true)
  ]))
}