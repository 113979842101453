import { Deserializable } from "@/interfaces";
import { FilePM } from ".";
import RegistrationEditVM from "./registration-edit-vm";

export default class RegistrationEditPM implements Deserializable<RegistrationEditPM> {
    id = 0;
    distributorCode = "";
    salesRepCode = "";
    customerName = "";
    applicationDetail = "";
    distributorInternalReference = "";
    countryCode = "";
    state = "";
    postalCode = "";
    projectName = "";
    part = "";
    incentiveProgramId = 0;
    marketGroupCode = "";
    startOfProduction = new Date();
    estimatedAnnualUnits = 0;
    targetBuyPrice = 0;
    targetResalePrice = 0;
    comments = "";
    registrationrowVersion = "";
    registrationApplicationRowVersion = "";
    registrationCustomerRowVersion = "";
    registrationPartRowVersion = "";
    docs = [] as FilePM[];
    
    prevRegistrationId?: number;
    prevRegistrationNumber?: string;

    /** Only for client to track dirty state for added files */
    newFiles = [] as FilePM[];

    deserialize(model: RegistrationEditPM): RegistrationEditPM {
        if(model) {
            Object.assign(this, model);
            if(model.startOfProduction) this.startOfProduction = new Date(model.startOfProduction);
        }
        return this;
    }

    copyFromViewModel(model: RegistrationEditVM): RegistrationEditPM {
        if(model) {
            Object.assign(this, model);
            if(model.docs) this.docs = model.docs.map(d => new FilePM().copyFromViewModel(d));
        }

        return this;
    }
}