import RegistrationNoteVM from "./registration-note-vm";

export default class RegistrationNotePM extends RegistrationNoteVM {
  registrationId?: number;
  registrationVersion?: number;

  /** Converts the given RegistrationNotePM to the internalComment Post model. */
  toInternalCommentPM(regId: number, regVersion: number): RegistrationNotePM {
    this.registrationId = regId;
    this.registrationVersion = regVersion;
    this.isInternal = true;

    return this;
  }

  /** Converts the given RegistrationNotePM to the externalComment Post model. */
  toExternalCommentPM(regId: number, regVersion: number): RegistrationNotePM {
    this.registrationId = regId;
    this.registrationVersion = regVersion;
    this.isInternal = false;

    return this;
  }
}
