import ApiClient  from "@/apiClient";
import DistributorsWithoutSettingsVM from "@/models/distributors-without-settings-vm";
import TypedApiClient  from "./typed-api-client";

const DISTRIBUTORS_API = 'distributors';

export default class DistributorSettingsService extends TypedApiClient {
    constructor(apiClient: ApiClient) {
        super(apiClient);
    }

    async getDistributorsWithoutSettings(): Promise<DistributorsWithoutSettingsVM> {
        return await this.get<DistributorsWithoutSettingsVM>(`${DISTRIBUTORS_API}/withoutSettings`, DistributorsWithoutSettingsVM) as DistributorsWithoutSettingsVM;
    }
}