import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.dataItem?.inEdit)
    ? (_openBlock(), _createBlock("td", _hoisted_1, [
        _createVNode("button", {
          type: "button",
          class: "is-primary button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.editHandler && _ctx.editHandler(...args)))
        }, " Edit ")
      ]))
    : (_openBlock(), _createBlock("td", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("button", {
            type: "button",
            class: "button is-success",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addUpdateHandler && _ctx.addUpdateHandler(...args)))
          }, " Save "),
          _createVNode("button", {
            type: "button",
            class: "button is-danger",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancelDiscardHandler && _ctx.cancelDiscardHandler(...args)))
          }, _toDisplayString(_ctx.cancelButtonLabel), 1)
        ])
      ]))
}