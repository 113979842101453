//@ts-expect-error
import { utils } from "hestia";
import { Deserializable } from "@/interfaces";

export default class DraftRegistrationVM implements Deserializable<DraftRegistrationVM> {
    id = 0;
    registrationNumber = "";
    description = "";
    lastEditDate = new Date();
    isDraftRegistration = false;

    // Only client
    lastEditDateForSearch = new Date();

    deserialize(model: DraftRegistrationVM): DraftRegistrationVM {
        if(model) {
            Object.assign(this, model);
            if(this.lastEditDate) {
                this.lastEditDate = new Date(this.lastEditDate);
                // Date without time is used for Kendo Grid search - no support for time filter
                this.lastEditDateForSearch = utils.stripTimeFromDate(this.lastEditDate) || this.lastEditDate;
            }
        }
        return this;
    }
}