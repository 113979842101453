import { DateTime } from "luxon";

export default class RegistrationNoteVM {
  id?: number;
  note?: string;
  isInternal?: boolean;
  userDisplayName?: string;
  editDate?: string;

  /** Gets the note with userDisplayName and timeStamp together. */
  get fullNote(): string {
    const timeStamp = DateTime.fromISO(this.editDate as string).toLocaleString(DateTime.DATETIME_MED);
    return `[${this.userDisplayName} ${timeStamp}]: ${this.note}`;
  }
}