import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { GridSortChangeEvent } from "@progress/kendo-vue-grid";
import { computed, ref, Ref } from "vue";

export default function useGridSorting(gridItems: Ref<any[]>) {
    const sort = ref<SortDescriptor[]>([]);
  
      const sortChange = (event: GridSortChangeEvent) => {
        sort.value = event.sort;
      };
      
      const sortedItems = computed(() => orderBy(gridItems.value, sort.value));

      return { sortChange, sortedItems, sort };
}