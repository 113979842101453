export function downloadFile(fileBlob: Blob, fileName: string) {
      const fileURL = URL.createObjectURL(fileBlob);
      
      // Create and click a download link on behalf of the user
      const a = document.createElement("a");
      a.href = fileURL;
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
}