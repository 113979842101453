import ApiClient  from "@/apiClient";
import TypedApiClient  from "./typed-api-client";
import { RegistrationNoteVM, RegistrationNotePM } from "@/models";

const REGISTRATION_NOTES_API_URL = "RegistrationNotes";
const INTERNAL_NOTE_SUFFIX_URL = "internal";
const EXTERNAL_NOTE_SUFFIX_URL = "external";


export default class RegistrationNoteService extends TypedApiClient {
  constructor(apiClient: ApiClient) {
    super(apiClient);
}

  async getRegistrationInternalNote(registrationId: number, versionId: number): Promise<RegistrationNoteVM[]> {
    return await this.get(`${REGISTRATION_NOTES_API_URL}/${registrationId}/${versionId}/${INTERNAL_NOTE_SUFFIX_URL}`, RegistrationNoteVM) as RegistrationNoteVM[];
  }

  async createInternalNote(note: RegistrationNotePM): Promise<RegistrationNoteVM> {
    return await this.post(note, RegistrationNoteVM, `${REGISTRATION_NOTES_API_URL}/${INTERNAL_NOTE_SUFFIX_URL}`, ) as RegistrationNoteVM;
  }

  async getRegistrationExternalNote(registrationId: number, versionId: number): Promise<RegistrationNoteVM[]> {
    return await this.get(`${REGISTRATION_NOTES_API_URL}/${registrationId}/${versionId}/${EXTERNAL_NOTE_SUFFIX_URL}`, RegistrationNoteVM) as RegistrationNoteVM[];
  }

  async createExternalNote(note: RegistrationNotePM): Promise<RegistrationNoteVM> {
    return await this.post(note, RegistrationNoteVM, `${REGISTRATION_NOTES_API_URL}/${EXTERNAL_NOTE_SUFFIX_URL}`, ) as RegistrationNoteVM;
  }
}