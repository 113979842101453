import { InlineEditable, Deserializable } from "@/interfaces";

export default class DistributorSettingVM implements InlineEditable, Deserializable<DistributorSettingVM> {
    id = 0;
    registrationRevenueMinimum = 0;
    distributorName = "";
    rowVersion?: string;

    // UI property for Kendo Grid edit feature
    inEdit = false;

    deserialize(model: DistributorSettingVM): DistributorSettingVM {
        if (model) {
            Object.assign(this, model);
        }

        return this;
    }
}