
import { defineComponent } from "vue";
// @ts-expect-error
import { modalCard } from "aml";
import { RegistrationService } from "@/services";
// @ts-expect-error 
import { readOnlyGrid, renderFilterableCellWithDatetime, renderIconCell } from "hestia";
import { PreviousRegistrationPM, PreviousRegistrationVM, PreviousRegistration } from "@/models";
import { GridColumnProps, GridRowClickEvent } from "@progress/kendo-vue-grid";

export default defineComponent({
  name: "PrevRegDialog",
  components: {
    modalCard,
    readOnlyGrid,
  },
  inject: ["api"],
  emits: ["done", "selected"],
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    prevRegistrationCriteria: {
      type: PreviousRegistrationPM,
      required: true
    }
  },
  data() {
    return {
      prevRegistrations: [] as PreviousRegistrationVM[],
      apiClient: new RegistrationService(this.api),
      isLoading: false,
      columns: [
        {
            filterable: false,
            sortable: false,
            resizable: false,
            cell: renderIconCell(() => "fas fa-check-circle"),
            className: "has-text-info is-size-6 is-centered",
            width: 45
        },
        {
          field: "registrationNumber",
          title: "Registration",
          filterable: true,
          filter: "text",
          className: "is-size-6",
        },
        {
          field: "status",
          title: "Status",
          filterable: true,
          filter: "text",
          className: "is-size-6",
        },
        {
          field: "requesterDisplayName",
          title: "Requester",
          filterable: true,
          filter: "text",
          className: "is-size-6",
        },
        {
          field: "expiryDate",
          title: "Expiry",
          filterable: true,
          filter: "date",
          width: 200,
          className: "is-size-6",
          cell: renderFilterableCellWithDatetime("expirationDate", "d-MMM-y")
        },
      ] as GridColumnProps[],
    };
  },
  methods: {
    async loadPrevRegistrations() {
      try {
        this.isLoading = true;
        const list = await this.apiClient.getPreviousRegistrations(this.prevRegistrationCriteria);
        this.prevRegistrations = list;
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.$emit("done");
    },
    rowClicked(event: GridRowClickEvent) {
      this.$emit("done");
      this.$closeModal(() => {
        const item: PreviousRegistrationVM = event.dataItem as PreviousRegistrationVM;
        this.$emit("selected", new PreviousRegistration(item.registrationId, item.registrationNumber as string));
      });
    }
  },
  watch: {
    isVisible(newVal) {
      if(newVal) this.loadPrevRegistrations();
    }
  }
});
