import ApiClient  from "@/apiClient";
import { ReviewDecisionPM } from "@/models";
import TypedApiClient  from "./typed-api-client";

const USER_TASK_REVIEW_API_URL = "UserTaskReview";
export default class UserTaskReviewService extends TypedApiClient {
  constructor(apiClient: ApiClient) {
    super(apiClient);
}

  async saveDecisionOutcome(decision: ReviewDecisionPM): Promise<void> {
    return await this.apiClient.post(decision, USER_TASK_REVIEW_API_URL + "/Decision");
  }
}