import { JsonClient } from 'hestia'

const INCENTIVE_PROGRAMS_OPTIONS_PATH = 'incentivePrograms/options';

class ApiClient {
  _root = process.env.VUE_APP_API_URL;

  constructor(app) {
    this.app = app;
    this.jsonClient = new JsonClient(this._root);
  }

  _filesUri = `${this._root}/files`;

  getAbortController() {
    return this.jsonClient.getAbortController();
  }

  addDraftRegistration(data) {
    return this.post(data, 'draftRegistrations');
  }

  getDraftRegistration(id) {
    return this.get(`draftRegistrations/${id}`);
  }

  createRegistration(data) {
    return this.post(data, 'registrations');
  }

  getRegistrationNumber(regId) {
    return this.get(`registrations/${regId}/registrationNumber`);
  }

  getCountries() {
    return this.getCache('countries/options');
  }

  getDistributors() {
    return this.getCache('distributors/options');
  }

  getFileUrl(fileName, referenceName) {
    return `${this._filesUri}/${fileName}/${referenceName}`;
  }

  getIncentivePrograms(distributorCode, countryCode) {
    let query = distributorCode ? `?distributorCode=${distributorCode}` : "";
    query += countryCode ? `${query ? "&" : "?"}countryCode=${countryCode}` : "";
    return this.getCache(INCENTIVE_PROGRAMS_OPTIONS_PATH + query);
  }

  getMarketGroups() {
    return this.getCache('marketgroups/options');
  }

  getReps() {
    return this.getCache('salesReps/options');
  }

  getRepForCountry(countryCode, postalCode) {
    const relativeUrl = postalCode ? `${countryCode}/${postalCode}` : `${countryCode}`
    return this.getCache(`salesReps/ByTerritory/${relativeUrl}`)
  }

  lookupCustomers(val) {
    return this.post(val, 'customers/lookup');
  }

  lookupParts(val) {
    return this.post(val, 'parts/lookup');
  }

  getPartDistributorBookCost(catalogPart, distributorCode) {
    return this.get(`distributors/${distributorCode}/bookCost/${catalogPart}`);
  }

  updateDraftRegistration(data) {
    return this.put(data, `draftRegistrations/${data.id}`);
  }

  logout() {
    return this.get("logout");
  }

  getAllowedFileExtensions() {
    return this.getCache('files/allowedExtensions');
  }

  getFileResponse(fileName, referenceName) {
    return this.get(`files/${encodeURIComponent(fileName)}/${referenceName}`);
  }

  async get(path, id, query) {
    await this.setToken();
    return this.jsonClient.get(path, id, query);
  }

  async getCache(path, id) {
    await this.setToken();
    return this.jsonClient.getCache(path, id);
  }

  async post(val, path) {
    await this.setToken();
    return this.jsonClient.post(val, path);
  }

  async put(val, path) {
    await this.setToken();
    return this.jsonClient.put(val, path);
  }

  async setToken() {
    const token = await this.app.$accessToken();
    this.jsonClient.setToken(token);
  }
}

export default ApiClient;