export enum RegistrationStatus {
    DRAFT = 1,
    SUBMITTED = 2,
    ACTIVE = 3, 
    PREEXP = 4,
    PENDRENEW = 5,
    EXPIRED = 6,
    REJECTED = 7,
    DELETED = 8,
    SUBMITTED_SR = 9,
    SUBMITTED_CR = 10,
    SUBMITTED_CD = 12,
    RENEW_SUBMITTED_CR = 13,
    ARCHIVED = 15
}

export const SubmissionStatuses = [
    RegistrationStatus.SUBMITTED,
    RegistrationStatus.SUBMITTED_CR,
    RegistrationStatus.SUBMITTED_CD,
    RegistrationStatus.SUBMITTED_SR
]

export const RenewalSubmissionStatuses = [
    RegistrationStatus.PENDRENEW,
    RegistrationStatus.RENEW_SUBMITTED_CR
]