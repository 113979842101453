import { UserAuth } from "@/helpers";
import { errorHandling, callback } from 'hestia';

export const names = {
  home: 'Home',
  registrationCreate: 'registrationCreate',
  registrationEdit: 'registrationEdit',
  registrationDetails: 'registrationDetails',
  incentivePrograms: 'incentivePrograms',
  distributorSettings: 'distributorSettings',
  search: 'search',
}

/** Interception before navigation to error page
 *  add supportEmail and flag if user is from Allegro
 */
const addErrorPageParams = (to) => {
  to.params.isAllegroUser = UserAuth.isAllegro;
  to.params.supportEmail = process.env.VUE_APP_SUPPORT_EMAIL;
}

export default [

    { path: '/', name: 'Home', component: () => import('@/views/Home.vue')},

    { 
      path: '/registrations/create', name: names.registrationCreate, component: () => import('@/views/registrations/create'),
      meta: { title: 'Create Registration', isUserAuthorized: () => UserAuth.canCreateRegistration }
    },

    { 
      path: '/registrations/edit/:registrationNumber', name: names.registrationEdit, component: () => import('@/views/registrations/edit'),
      meta: { title: 'Edit Registration' }
    },

    { path: '/registrations/details/:registrationNumber', name: names.registrationDetails, component: () => import('@/views/registrations/details'),
      meta: { title: 'Registration Details' }
    },

    { path: '/incentivePrograms', name: names.incentivePrograms, component: () => import('@/views/incentivePrograms/index.vue'),
      meta: { title: 'Incentive Programs', isUserAuthorized: () => UserAuth.canViewIncentivePrograms }
    },

    {    
        path: '/distributorSettings', 
        name: names.distributorSettings, 
        component: () => import('@/views/distributorSettings/index.vue'),
        meta: { title: 'Distributor Settings', isUserAuthorized: () => UserAuth.canViewDistributorSettings }
    },

    {    
        path: '/search', 
        name: names.search, 
        component: () => import('@/views/registrationSearch/index.vue'),
        meta: { title: 'Search results' }
    },

    {
        path: '/callback', name: 'authCallback', component: callback,
        meta: { allowAnonymous: true, viewOnly: true }
    },
    {
        path: '/logout', name: 'Logout', component: () => import('@/views/Logout.vue'),
        meta: { allowAnonymous: true, viewOnly: true }
    },
    {
        path: '/error', name: 'Error', component: errorHandling.page,
        meta: { allowAnonymous: true },
        beforeEnter: (to, from, next) => {
          addErrorPageParams(to);
          next();
        }
    },
    {
      path: '/:pathMatch(.*)*',
      name: "NotFound",
      meta: { allowAnonymous: true },
      component: errorHandling.page,
      beforeEnter: (to, from, next) => {
        addErrorPageParams(to);
        to.params.statusCode = 404;
        next();
      } 
    },
]