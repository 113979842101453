import ApiClient  from "@/apiClient";
import { UserTaskReviewVM } from "@/models";
import TypedApiClient  from "./typed-api-client";

const REGISTRATION_USER_TASK_REVIEW_API_URL = "RegistrationUserTaskReview";
export default class RegistrationUserTaskReviewService extends TypedApiClient {
  constructor(apiClient: ApiClient) {
    super(apiClient);
}

  async getFirstOpenUserTask(registrationId: number, userTaskTypeIds: number[]): Promise<UserTaskReviewVM> {
    const query = userTaskTypeIds.map(id => `type=${id}`).join("&");
    return await this.apiClient.get(`${REGISTRATION_USER_TASK_REVIEW_API_URL}/Registrations/${registrationId}/UserTaskReview/FindFirstOpen`, undefined, query) as UserTaskReviewVM;
  }
}