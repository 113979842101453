import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '@/store'
import app from '@/main'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


const isUserAuthorized = ({ meta: { isUserAuthorized }, fullPath }) => {
    if ((isUserAuthorized && isUserAuthorized()) === false) {
        app.$router.push({ 
            name: "Error", 
            params: { 
                statusCode: 403, 
                originalUrl: window.location.host + fullPath
            }});
        return false;
    }
    return true;
}

const authGuard = (to) => {
    if (to.meta.allowAnonymous
        || store.getters['isAuthenticated']) {
            return isUserAuthorized(to);
        }

    app.$signIn();
    return false;
}

const setTitle = (to) => {
    const suffix = process.env.VUE_APP_NAME;
    const prefix = to.meta.title ? to.meta.title + ' - ' : '';
    document.title = prefix + suffix;
}

router.beforeEach((to, from, next) => {
    if (!authGuard(to)) {
        next(false);
        return;
    }

    setTitle(to);
    next();
});
export default router

export { names as routeNames } from './routes'