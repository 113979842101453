
import { defineComponent } from "vue";
// @ts-expect-error
import { modalDialog } from "aml";

export default defineComponent({
  name: "RegistrationExpireNowDialog",
  props: {
    registrationNumber: {
      type: String,
      required: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    modalDialog,
  },
  emits: ["resolveDialogAction", "update:modelValue"],
  data() {
    return {
      expireNowComments: "",
      formId: "expireNowCommentsForm"
    };
  },
  methods: {
    resolveDialogAction(proceed: boolean) {
      if (proceed) {
        if (!this.isFormValid()) return;
        this.$emit("update:modelValue", this.expireNowComments);
        this.$emit("resolveDialogAction", true);
      } else {
        this.$emit("resolveDialogAction", false);
      }
    },
    isFormValid() {
      const fieldErrors = this.$validate(this.formId);
      return !fieldErrors.length;
    },
  },
});
