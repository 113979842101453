import { GridPagerSettings } from "@progress/kendo-vue-grid";
import { computed, Ref, ref, watch } from "vue";

export default function useGridPaging(gridItems: Ref<any[]>, pageSizes: number[]) {
    const pageableDefaults = {
        info: true,
        type: 'numeric',
        pageSizes,
        previousNext: true
      } as GridPagerSettings;
    const pageable = ref<GridPagerSettings | null>({...pageableDefaults});
    const skip =  ref(0);
    const take = ref(pageSizes[0]);
    const pageSize = pageSizes[0];
    const total = computed(() => gridItems?.value?.length ?? 0);
    const pageItems = computed(() => gridItems.value.slice(skip.value, take.value + skip.value));

    const pageChangeHandler = (event: any) => {
        skip.value = event.page.skip;
        take.value = event.page.take;
    };

    // Hide paging if all items fits on the smallest page size
    watch(total, (newValue: number) => {
        pageable.value = newValue <= pageSizes[0] ? null : {...pageableDefaults};
    }, {immediate: true});

    return { pageable, skip, take, pageSize, total, pageItems, pageChangeHandler };
}