export enum FileExtensionType {
  PDF = "pdf",
  XLS = "xls",
  XLSX = "xlsx",
  DOC = "doc",
  DOCX = "docx"
}

export enum FileIcons {
  PDF = "fas fa-file-pdf",
  EXCEL = "fas fa-file-excel",
  WORD = "fas fa-file-word",
  TXT = "fas fa-file-text",
}

export const FileExtensionIcons: Record<string, string> = {
  [FileExtensionType.PDF]: FileIcons.PDF,
  [FileExtensionType.XLS]: FileIcons.EXCEL,
  [FileExtensionType.XLSX]: FileIcons.EXCEL,
  [FileExtensionType.DOC]: FileIcons.WORD,
  [FileExtensionType.DOCX]: FileIcons.WORD
};

export function getFileIcon(docFileName: string) {
  const docNameArr = docFileName?.split(".");
  if (docNameArr) {
    return FileExtensionIcons[docNameArr[docNameArr.length - 1]] || "fas fa-file";
  }
}
