import { InlineEditable } from "@/interfaces";
import { DateTime } from "luxon";

export default class IncentiveProgram implements InlineEditable {
    id = 0;
    incentiveProgramName = "";
    incentiveProgramDescription = "";
    effectiveFrom = DateTime.local().plus({ days: 1 }).startOf("day").toJSDate();
    effectiveTo = DateTime.local().plus({ days: 1 }).startOf("day").toJSDate();
    rowVersion?: string;

    distributorCodes: string[] = []

    // UI property for Kendo Grid edit feature
    inEdit = false;

    // TODO: add generic deserialization logic
    deserialize(model: IncentiveProgram): IncentiveProgram {
        if(model) {
            Object.assign(this, model);
            if(this.effectiveFrom) this.effectiveFrom = new Date(this.effectiveFrom);
            if(this.effectiveTo) this.effectiveTo = new Date(this.effectiveTo);
        }

        return this;
    }
}