export default class SubmitRenewalPM {
    registrationId: number;
    comments: string;
    rowVersion: string;

    constructor(registrationId: number, comments: string, rowVersion: string) {
        this.registrationId = registrationId;
        this.comments = comments;
        this.rowVersion = rowVersion;
    }
}