import { UserRole } from "@/enums";
import store from '@/store'

function hasRole(role: string) {
    return store.getters["auth/hasRole"](role);
}

export class UserAuthHelper {
    get isAllegro() {
        return hasRole(UserRole.IsAllegro);
    }

    /** Registrations permissions */

    get canCreateRegistration() {
        return hasRole(UserRole.CanCreateRegistrations);
    }

    get canViewRegistrationHistory() {
        return hasRole(UserRole.CanViewRegistrationHistory);
    }

    get canExpireNow() {
        return hasRole(UserRole.CanExpireNow);
    }

    /** Incentive programs permissions */

    get canViewIncentivePrograms() {
        return hasRole(UserRole.CanViewIncentivePrograms) || hasRole(UserRole.CanManageIncentivePrograms);
    }

    get canManageIncentivePrograms() {
        return hasRole(UserRole.CanManageIncentivePrograms)
    }

    /** Distributor settings permissions */

    get canViewDistributorSettings() {
        return hasRole(UserRole.CanViewDistributorSettings) || hasRole(UserRole.CanManageDistributorSettings);
    }

    get canManageDistributorSettings() {
        return hasRole(UserRole.CanManageDistributorSettings);
    }
}

export const UserAuth = new UserAuthHelper();