import ApiClient  from "@/apiClient";
import { DraftRegistrationVM } from "@/models";
import TypedApiClient  from "./typed-api-client";

const DRAFT_REGISTRATIONS_API_URL = "DraftRegistrations";
export default class DraftRegistrationService extends TypedApiClient {
  constructor(apiClient: ApiClient) {
    super(apiClient);
}

  async getDrafts(): Promise<DraftRegistrationVM[]> {
    return await this.get<DraftRegistrationVM>(`${DRAFT_REGISTRATIONS_API_URL}`, DraftRegistrationVM) as DraftRegistrationVM[];
  }
}