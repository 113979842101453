import { createStore } from 'vuex'
import { auth, errorHandling } from 'hestia'
import { modal, loadingManager } from 'aml'

export default createStore({
    state: {
    },
    getters: {
      isAuthenticated: (_, getters) => getters['auth/isAuthenticated'],
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth: auth.store,
        modal: modal.store,
        error: errorHandling.store,
        loadingManager: loadingManager.store,
        // formValidation store used only as a namespace for submodules for form specific stores
        formValidation: { namespaced: true }
    }
})