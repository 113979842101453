import { Deserializable } from "@/interfaces";

export default class QuoteVM implements Deserializable<QuoteVM> {
 quoteId: number | null = null;
 rfqId = 0;
 applicationName = "";
 quantity = 0;
 distributorCost = 0;
 distributorResale = 0;
 startOfProduction: Date | null = null;

 deserialize(model: QuoteVM): QuoteVM {
   if(model) {
     Object.assign(this, model);
     if(model.startOfProduction) this.startOfProduction = new Date(model.startOfProduction);
   }

   return this;
 }
}