import ApiClient  from "@/apiClient";
import { IncentiveProgram } from "@/models";
import TypedApiClient  from "./typed-api-client";

const INCENTIVE_PROGRAMS_API_URL = "incentiveprograms";
export default class IncentiveProgramsService extends TypedApiClient {
  constructor(apiClient: ApiClient) {
    super(apiClient);
}

  async getIncentivePrograms(includeExpired: boolean): Promise<IncentiveProgram[]> {
    const includeExpiredQuery = includeExpired ? "?includeExpired=true" : "";
    return await this.get<IncentiveProgram>(INCENTIVE_PROGRAMS_API_URL + includeExpiredQuery, IncentiveProgram) as IncentiveProgram[];
  }

  async createIncentiveProgram(program: IncentiveProgram): Promise<IncentiveProgram> {
    return await this.post<IncentiveProgram>(program, IncentiveProgram, INCENTIVE_PROGRAMS_API_URL) as IncentiveProgram;
    
  }

  async updateIncentiveProgram(program: IncentiveProgram): Promise<IncentiveProgram> {
    return await this.put<IncentiveProgram>(program, IncentiveProgram, `${INCENTIVE_PROGRAMS_API_URL}/${program.id}`) as IncentiveProgram;
  }
}