import { Deserializable } from "@/interfaces";
import { RegistrationStatus } from "@/enums";
import RegistrationDocumentVM from "./registration-document-vm";

export default class RegistrationEditVM implements Deserializable<RegistrationEditVM> {
    id = 0;
    registrationNumber = "";
    statusId = RegistrationStatus.DRAFT;
    distributorCode = "";
    salesRepCode = "";
    customerName = "";
    applicationDetail = "";
    distributorInternalReference = "";
    countryCode = "";
    state = "";
    postalCode = "";
    projectName = "";
    part = "";
    registrationTypeId = 0;
    incentiveProgramId = 0;
    marketGroupCode = "";
    startOfProduction = new Date();
    estimatedAnnualUnits = 0;
    targetBuyPrice = 0;
    targetResalePrice = 0;
    comments = "";
    registrationRowVersion = "";
    registrationApplicationRowVersion = "";
    registrationCustomerRowVersion = "";
    registrationPartRowVersion = "";
    docs = [] as RegistrationDocumentVM[];
    
    prevRegistrationId?: number;
    prevRegistrationNumber?: string;

    deserialize(model: RegistrationEditVM): RegistrationEditVM {
        if(model) {
            Object.assign(this, model);
            if(model.startOfProduction) this.startOfProduction = new Date(model.startOfProduction);
        }
        return this;
    }
}