import { RegistrationStatus } from "@/enums";
import { Deserializable } from "@/interfaces";

export default class RegistrationSearchItem implements Deserializable<RegistrationSearchItem> {
  registrationId = 0;
  registrationNumber?: string;
  distributorName?: string;
  customerName?: string;
  countryName?: string;
  catalogPart?: string;
  price?: number;
  resale?: number;
  designValue?: number;
  expirationDate?: Date;
  hasDocs?: boolean;
  registrationRowVersion = "";
  registrationStatusName?: string;
  registrationStatusId?: RegistrationStatus;
  canModify = false;
  assignedToDisplayName?: string;

  deserialize(model: RegistrationSearchItem): RegistrationSearchItem {
    if (model) {
      Object.assign(this, model);
      
      if (model.expirationDate) {
          this.expirationDate = new Date(model.expirationDate);
      }
    }

    return this;
  }
}
