import ApiClient from "@/apiClient";
import { Deserializer } from "@/helpers";

export default class TypedApiClient {
  protected apiClient: ApiClient;

  constructor(apiClient: any) {
    this.apiClient = apiClient;
  }

  async get<T>(path: string, ctorFunction: new () => T, id?: number, query?: string): Promise<T | T[]> {
    const response = await this.apiClient.get(path, id, query);
    return Deserializer.deserialize<T>(response, ctorFunction);
  }

  async post<T>(val: T, ctorFunction: new () => T, path?: string): Promise<T | T[]> {
    const response = await this.apiClient.post(val, path);
    return Deserializer.deserialize<T>(response, ctorFunction);
  }

  async put<T>(val: T, ctorFunction: new () => T, path?: string): Promise<T | T[]> {
    const response = await this.apiClient.put(val, path);
    return Deserializer.deserialize<T>(response, ctorFunction);
  }

  async getCache<T>(path: string, ctorFunction: new () => T, id?: number): Promise<T | T[]> {
    const response = await this.apiClient.getCache(path, id);
    return Deserializer.deserialize<T>(response, ctorFunction);
  }
}