//@ts-expect-error
import { NavbarMenuItem } from "hestia";
import { UserAuth } from "@/helpers";

const createRegistrationMenuItem = new NavbarMenuItem("Create Registration", "/registrations/create", "fas fa-plus-circle");
const incentiveProgramsMenuItem = new NavbarMenuItem("Incentive Programs", "/incentivePrograms", "fas fa-chart-line");
const distributorSettingsMenuItem = new NavbarMenuItem("Distributor Settings", "/distributorSettings", "fas fa-users-cog");

function GetAdminMenuItems(): NavbarMenuItem[] {
    return [
        UserAuth.canViewIncentivePrograms && incentiveProgramsMenuItem,
        UserAuth.canViewDistributorSettings && distributorSettingsMenuItem,
    ].filter(i => i);
}

function GetAdminSettingsMenuItem(adminMenuItems: NavbarMenuItem[]): NavbarMenuItem | boolean {
    return adminMenuItems.length > 0 && new NavbarMenuItem("Admin Settings", "", "fas fa-cogs", adminMenuItems);
}

export function GetNavbarMenuItems() {
    const adminMenuItems = GetAdminMenuItems();

    return [
        UserAuth.canCreateRegistration && createRegistrationMenuItem,
        GetAdminSettingsMenuItem(adminMenuItems)
    ].filter(i => i);
}