
import { defineComponent, PropType } from "vue";
import { RegistrationUserTaskReviewService, UserTaskReviewService } from "@/services";
import {
  RegistrationDetailVM,
  ReviewDecisionPM,
  UserTaskReviewVM,
} from "@/models";
import { UserTaskType } from "@/enums";
// @ts-expect-error
import { reviewManager } from "hestia";

type Decision = { outcomeId: number; comments: string };

export default defineComponent({
  name: "RegistrationReviewManager",
  components: {
    reviewManager,
  },
  inject: ["api"],
  emits: ["reviewSaved"],
  props: {
    registration: {
      type: Object as PropType<RegistrationDetailVM>,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      userTaskReviewVM: null as null | UserTaskReviewVM,
    };
  },
  methods: {
    async getFirstOpenUserTask() {
      if (!this.userTaskTypes?.length) return;
      try {
        this.isLoading = true;
        this.userTaskReviewVM = await this.registrationUserTaskReviewServiceApi.getFirstOpenUserTask(
          this.registration.id,
          this.userTaskTypes
        );
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    },
    async saveReviewDecision(decision: Decision) {
      if (!this.userTaskReviewVM || !decision) return;
      const reviewDecision = new ReviewDecisionPM(
        decision.outcomeId,
        this.registration.id,
        this.userTaskReviewVM?.registrationRowVersion,
        this.userTaskReviewVM?.userTaskId,
        this.userTaskReviewVM?.userTaskRowVersion,
        decision.comments
      );
      try {
        this.isLoading = true;
        await this.userTaskReviewServiceApi.saveDecisionOutcome(reviewDecision);
        this.$toast({
          message: "Review decision saved",
          type: "success",
        });
        this.$emit("reviewSaved");
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    userTaskReviewServiceApi(): UserTaskReviewService {
      return new UserTaskReviewService(this.api);
    },
    registrationUserTaskReviewServiceApi(): RegistrationUserTaskReviewService {
      return new RegistrationUserTaskReviewService(this.api);
    },
    isRenewal(): boolean {
      return typeof(this.registration.maxRecommendedRenewalCount) === "number";
    },
    doesRenewalExceedsMaxRecommendedCount(): boolean {
      return (this.registration.version + 1) > this.registration.maxRecommendedRenewalCount;
    },
    userTaskTypes(): number[] {
      return [
        UserTaskType.Registration_SalesRep_Review,
        UserTaskType.Registration_ChannelMgr_Review,
        UserTaskType.Registration_ChannelDir_Review,
        UserTaskType.Renewal_ChannelMgr_Review
      ];
    },
  },
  watch: {
    registration(newValue: RegistrationDetailVM) {
      this.userTaskReviewVM = null;
      if (newValue) this.getFirstOpenUserTask();
    },
  },
});
